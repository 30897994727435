<template>
  <div class="stepper">
    <div
      v-for="(step, n) in steps"
      :key="n"
      class="stepper-step"
    >
      <div
        class="stepper-step-item"
        :class="{
          _active: activeStep === n + 1,
        }"
      >
        <div class="stepper-step-item__num">
          {{ n + 1 }}
        </div>
        <div class="stepper-step-item-text">
          <h3 class="__header">
            {{ step.title }}
          </h3>
          <p>
            {{ step.description }}
          </p>
        </div>
      </div>
      <div
        v-if="n + 1 < steps.length"
        class="stepper-step__divider"
      >
				&nbsp;
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@mixin createStepers($stepper-size, $stepper-font-size) {
  .stepper-step-item__num {
    height: $stepper-size;
    width: $stepper-size;
    background-color: #2979FF;
    border-radius: ($stepper-size/2);
    text-align: center;
    font-size: $stepper-font-size;
    line-height: $stepper-size;
    letter-spacing: 0.03em;
    font-weight: bold;
    color: #ffffff;
  }

  .stepper-step-item._active .stepper-step-item__num {
    margin-left: -5px;
    height: $stepper-size + 10px;
    width: $stepper-size + 10px;
    border-radius: (($stepper-size + 10px)/2);
    background-color: #2979FF;
    border: 10px double #ffffff;
    line-height: $stepper-size - 10px;
  }

  .stepper-step__divider {
    border-left: 2px solid #2979FF;
    margin-left: ($stepper-size/2) + 5px;
    height: 82px;
    z-index: 10;
    position: relative;
    margin-top: -3px;
    margin-bottom: -3px;
  }
}

.stepper-step-item {
  display: flex;
  align-items: center;
  margin-left: 5px;
  color: #000000;
  line-height: 24px;
  letter-spacing: 0;
  font-style: normal;
  font-size: 16px;
}

@include createStepers($stepper-size: 80px, $stepper-font-size: 32px);

.stepper-step-item-text {
  position: absolute;
  left: (80px + (16px * 2));
}

.stepper-step-item._active .stepper-step-item-text {
  color: #171725;
}

// изменение stepper-step-item-text
@media (max-width: 920px) and (min-width: 769px) {
  .stepper-step-item-text {
    left: 102px;
    width: 170px;
  }

  .stepper-step-item-text h3 {
    font-size: 23px;
    line-height: 26px;
    margin-bottom: 5px;
  }

  .stepper-step-item-text p {
    font-size: 16px;
    line-height: 19px;
  }
}

@media (max-width: 768px) {
  @include createStepers($stepper-size: 48px, $stepper-font-size: 20px);

  .stepper-step-item {
    align-items: baseline;
  }

  .stepper-step-item._active .stepper-step-item__num {
    border-color: #2979FF;
    height: 48px;
    width: 48px;
    margin-left: 0;
    line-height: 48px - 20px;
  }

  .stepper-step-item-text {
    position: absolute;
    left: 63px;
  }

  .stepper-step-item-text .__header {
    margin: 0.5rem 0;
  }

  .stepper-step__divider {
    height: 71px;
  }
}
</style>

<script>
export default {
  name: "Stepper",
  props: {
    steps: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activeStep: 2,
    };
  },
};
</script>
